@import 'assets/font-awesome/css/all.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  width: 100%;
  @apply bg-gray-700;
}

.max-metco-container {
  max-width: 100%;
}

@responsive {
  .metco-container-width {
    max-width: 100%;
    width: 1920px;
  }
}

.max-table-width {
  max-width: 1024px;
}

.sidebar-min-width {
  width: 300px;
}

.primary-button {
  @apply bg-primary text-gray-50 hover:bg-primary-600 border-primary-300 border-t cursor-pointer transition ease-in-out;
}

.cancel-button {
  @apply bg-gray-900 text-gray-400 hover:text-gray-100 cursor-pointer transition ease-in-out;
}

.destructive-button {
  @apply bg-destructive text-gray-50 hover:bg-destructive-600 border-destructive-300 border-t cursor-pointer transition ease-in-out;
}

.warning-button {
  @apply bg-warning text-gray-50 hover:bg-warning-600 border-warning-300 border-t cursor-pointer transition ease-in-out;
}

.primary-text-button {
  @apply text-left text-primary hover:text-primary-600 cursor-pointer transition ease-in-out;
}

.cancel-text-button {
  @apply text-left text-accent hover:text-accent-600 cursor-pointer transition ease-in-out;
}

.destructive-text-button {
  @apply text-left text-destructive hover:text-destructive-600 cursor-pointer transition ease-in-out;
}

.warning-text-button {
  @apply text-left text-warning hover:text-warning-600 cursor-pointer transition ease-in-out;
}

.dialog-button {
  @apply rounded-b-xl rounded-t-none flex-grow;
}

.small-icon-button-container {
  @apply rounded-full hover:text-primary hover:bg-gray-700 text-gray-400 cursor-pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  overflow: hidden;
}

.bg-gray {
  background-color: #343434;
}

.mke-warning {
  color: #f89845;
}

.info {
  color: #3fa9f5;
}

.warning {
  color: #fcee21;
}

.input-label {
  @apply block text-sm font-medium text-gray-200;
}

.input {
  @apply outline-none block w-full sm:text-sm rounded-xl py-2 pl-4 pr-1 bg-gray-300 border-4 border-gray-600 focus:border-primary-500 focus:bg-gray-200 focus:outline-none text-black;
}

.modal-overlay-background {
  @apply fixed inset-0 bg-gray-200 opacity-20 transition-opacity;
}

.header-text {
  @apply xl:text-base text-gray-50 font-semibold;
}

.sub-header-text {
  @apply xl:text-base text-gray-400 font-medium;
}

.label-text {
  @apply text-gray-50 text-sm font-semibold;
}

.label-text-dark {
  @apply text-gray-900 text-sm font-semibold;
}

.label-text-positive {
  @apply text-positive text-sm font-semibold;
}

.label-text-negative {
  @apply text-destructive text-sm font-semibold;
}

.value-text {
  @apply text-gray-400 text-sm font-medium;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.list {
  @apply divide-y divide-gray-700 bg-gray-800;
}

.list-item {
  @apply bg-gray-900 hover:bg-gray-800 border-b border-gray-900;
}

.table {
  @apply border-gray-900 border;
}

.table-head {
  @apply bg-gray-900;
}

.table-body {
  @apply bg-gray-800;
}

.table-row {
  @apply border border-gray-900 hover:bg-gray-700;
}

.table-cell {
  @apply px-6 py-3 text-left whitespace-nowrap;
}

.h-camera {
  height: 24rem;
}
